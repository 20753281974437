// @ts-nocheck
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, DueDate, Tabs, Text, Timeline } from '@cavela/ui';
import {
  Download,
  InsertDriveFile,
  KeyboardArrowRightRounded,
  OpenInNew
} from '@mui/icons-material';

import useSupplierUpdates from 'src/hooks/useSupplierUpdates';

import { Modal } from '@mantine/core';

import { MappedProductDetail } from '../../types/ProductTypes';
import ErrorNotification from '../ErrorNotification/ErrorNotification';
import UpdatesContent from '../UpdatesContent/UpdatesContent';

interface Props {
  product?: MappedProductDetail;
}

type StageId =
  | 'rfq_received'
  | 'rfq_sent'
  | 'quotes_received'
  | 'samples_ordered'
  | 'samples_produced';

const stages = {
  rfq_received: {
    variant: 'in-progress',
    text: 'Sourcing'
  },
  rfq_sent: {
    variant: 'in-progress',
    text: 'Sourcing'
  },
  quotes_received: {
    variant: 'new-update',
    text: 'Sourcing'
  },
  samples_ordered: {
    variant: 'in-progress',
    text: 'Sampling'
  },
  samples_produced: {
    variant: 'new-update',
    text: 'Sampling'
  },
  order_produced: {
    variant: 'in-progress',
    text: 'Ordered'
  }
};

const stageNodes = ['Sourcing', 'Sampling', 'Ordered'];

const DEFAULT_TAB = 'Overview';

const SupplierTabs = ({ product }: Props) => {
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  const [isUpdatesShown, setIsUpdatesShown] = useState(false);
  const navigate = useNavigate();

  const productId = product?.productId;
  const files = product?.detail?.lifecycle
    ?.flatMap((stage) => stage.files)
    .filter((files) => files);

  const { supplierUpdates, loading, errorCode, fetchUpdates } =
    useSupplierUpdates({ productId });

  const onClickUpdates = () => setIsUpdatesShown(true);

  const onClickCloseUpdates = () => setIsUpdatesShown(false);

  const { id: stageId, estimatedDate } =
    product?.stages.find(({ status }) => status !== 'done') || {};

  const { questions } = product?.stages[0];

  const isComplete = product?.stages.every(
    ({ status = '' }) => status === 'done'
  );

  const { text } = stages[stageId as StageId] || {};

  const stageLabel = isComplete ? stages?.order_produced.text : text;
  const stageIndex = stageNodes.indexOf(stageLabel);
  const currentTab = supplierUpdates[activeTab] || {};

  // Convert onboarding answers to updates

  const onboardingAnswers = questions
    .filter(({ answeredAt }) => answeredAt)
    .map((question, index) => ({
      id: `${stageIndex}${index}`,
      updateType: 'in-progress',
      createdAt: question.createdAt,
      message: question.question,
      messageHTML: question.question,
      questionId: index,
      productStage: 'rfq_received',
      relevant: true,
      product: {
        id: product?.productId,
        detail: {
          name: product?.productName
        }
      },
      question,
      supplierCloak: 'Onboarding'
    }))
    .reverse();

  const relevantProductUpdates = Object.values(currentTab).filter(
    ({ relevant }) => relevant
  );

  const getTabUpdates = (tab: string) => {
    return Object.values(supplierUpdates[tab]).filter(
      ({ relevant }) => relevant
    );
  };

  const isEmpty = !relevantProductUpdates?.length;

  const supplierUpdatesKeysWithoutDefault = Object.keys(supplierUpdates).filter(
    (key) => key !== DEFAULT_TAB
  );

  supplierUpdatesKeysWithoutDefault.sort();

  const supplierUpdatesWithDefaultFirst = {
    [DEFAULT_TAB]: supplierUpdates[DEFAULT_TAB],
    ...Object.assign(
      {},
      ...supplierUpdatesKeysWithoutDefault.map((key) => ({
        [key]: supplierUpdates[key]
      }))
    )
  };

  const tabs = Object.keys(supplierUpdatesWithDefaultFirst).map(
    (tab, index) => {
      const count =
        index === 0
          ? 0
          : supplierUpdatesWithDefaultFirst[tab].filter(
              ({ relevant }) => relevant
            ).length;

      return {
        [tab]: {
          count,
          element: (
            <>
              <div className="flex items-center justify-between mt-5 mb-3">
                <Text heading style={{ color: 'black', fontSize: '1.5em' }}>
                  Updates
                </Text>
                <div className="flex items-center justify-end">
                  <Button
                    variant="link"
                    style={{ padding: 0 }}
                    onClick={onClickUpdates}
                  >
                    Previous Updates&nbsp;
                    <KeyboardArrowRightRounded />
                  </Button>
                </div>
              </div>
              {!loading && (
                <>
                  {!isEmpty ? (
                    <UpdatesContent
                      updates={relevantProductUpdates.concat(
                        activeTab === DEFAULT_TAB
                          ? []
                          : getTabUpdates(activeTab)
                      )}
                      onUpdate={fetchUpdates}
                    />
                  ) : (
                    <div className="bg-white rounded-md shadow-sm p-4 animate-fadeIn">
                      ✅&nbsp;&nbsp;You've caught up on all updates!
                    </div>
                  )}
                </>
              )}

              {(product?.rfq || files.length > 0) && (
                <>
                  <div className="flex items-center justify-between mt-5 mb-3">
                    <Text heading style={{ color: 'black', fontSize: '1.5em' }}>
                      Documents
                    </Text>
                  </div>

                  {files.length > 0 && (
                    <div className="bg-white shadow-sm rounded-xl px-4 animate-fadeIn">
                      <table className="w-full text-sm">
                        <thead>
                          {files?.length > 0 && (
                            <tr className="border-b border-gray-400">
                              <th
                                className="text-left font-medium p-4 pb-2"
                                style={{
                                  fontFamily: 'Poppins',
                                  fontSize: '13px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  textAlign: 'left',
                                  color: '#6E747D'
                                }}
                              >
                                FILE NAME
                              </th>
                              <th className="flex-grow"></th>{' '}
                              <th
                                className="text-right font-medium p-4 pb-2 pr-2"
                                style={{
                                  fontFamily: 'Poppins',
                                  fontSize: '13px',
                                  fontWeight: 500,
                                  lineHeight: '16px',
                                  textAlign: 'right',
                                  color: '#6E747D'
                                }}
                              >
                                UPLOADED DATE
                              </th>
                              <th className="w-12"></th>
                            </tr>
                          )}
                        </thead>
                        <tbody>
                          {files.length > 0 &&
                            files?.map((file, index) => (
                              <tr
                                className={`${index !== files.length - 1 ? 'border-b border-gray-300' : ''}`}
                              >
                                <td
                                  className="p-4"
                                  style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    lineHeight: '18px',
                                    textAlign: 'left',
                                    color: 'black'
                                  }}
                                >
                                  <InsertDriveFile className="text-[#6E747D] mb-1 mr-1" />
                                  {file.name}
                                </td>
                                <td></td>
                                <td
                                  className="p-4 pr-2"
                                  style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    lineHeight: '18px',
                                    textAlign: 'right',
                                    color: 'black'
                                  }}
                                >
                                  {new Date(
                                    file.upload_date
                                  ).toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric'
                                  })}
                                </td>
                                <td>
                                  <button
                                    className="hover:scale-105 transition-all duration-300 ml-4"
                                    onClick={() => {
                                      window.open(file.url, '_blank');
                                    }}
                                  >
                                    <Download className="text-[#6E747D]" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {product?.rfq && (
                    <div className="bg-white shadow-sm rounded-xl px-4 animate-fadeIn mt-4">
                      <table className="w-full text-sm">
                        <thead>
                          <tr className="border-b border-gray-400">
                            <th
                              className="text-left font-medium p-4 pb-2"
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: '13px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                textAlign: 'left',
                                color: '#6E747D'
                              }}
                            >
                              FILE NAME
                            </th>
                            <th className="flex-grow"></th>{' '}
                            <th
                              className="text-right font-medium p-4 pb-2 pr-2"
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: '13px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                textAlign: 'right',
                                color: '#6E747D'
                              }}
                            >
                              CREATED DATE
                            </th>
                            <th className="w-12"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              const rfqUrl =
                                '/new-rfq?rfq_id=' +
                                product?.rfq?.id +
                                '?review=true';
                              navigate(rfqUrl);
                            }}
                          >
                            <td
                              className="p-4"
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: '13px',
                                fontWeight: 500,
                                lineHeight: '18px',
                                textAlign: 'left',
                                color: 'black'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <img
                                  src="/icons/star.svg"
                                  className="mr-1 mb-1"
                                  alt="rfqFiles"
                                />
                                Your Quote Request
                              </div>
                            </td>
                            <td></td>
                            <td
                              className="p-4 pr-2"
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: '13px',
                                fontWeight: 500,
                                lineHeight: '18px',
                                textAlign: 'right',
                                color: 'black'
                              }}
                            >
                              {new Date(
                                product?.rfq?.created_at
                              ).toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric'
                              })}
                            </td>
                            <td>
                              <button
                                className="hover:scale-105 transition-all duration-300 ml-4"
                                onClick={() => {
                                  const rfqUrl =
                                    '/new-rfq?rfq_id=' +
                                    product?.rfq?.id +
                                    '?review=true';
                                  navigate(rfqUrl);
                                }}
                              >
                                <OpenInNew className="text-[#6E747D]" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </>
          )
        }
      };
    }
  );

  const flatTabs = Object.assign({}, ...tabs);

  return (
    <>
      <div className="flex items-center gap-4 my-8">
        <Modal
          opened={isUpdatesShown}
          centered
          fullScreen={true}
          onClose={onClickCloseUpdates}
        >
          <div className="flex flex-col gap-4">
            <Text heading>All updates</Text>
            <UpdatesContent
              updates={
                !errorCode &&
                !loading &&
                Object.values(
                  supplierUpdatesWithDefaultFirst[DEFAULT_TAB]
                ).concat(onboardingAnswers)
              }
              onUpdate={fetchUpdates}
            />
          </div>
        </Modal>
        <div className="hidden md:block">
          <Timeline
            direction="vertical"
            small
            stage={stageIndex}
            labels={stageNodes}
            style={{ margin: '0 3rem 0 5rem' }}
          />
        </div>
        <div className="flex flex-col gap-4 justify-start items-start">
          <div className="flex gap-4 items-center justify-start">
            {text && (
              <span className="relative flex h-4 w-4">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[var(--mantine-color-cavela-primary-5)] opacity-50" />
                <span className="relative inline-flex rounded-full h-3 w-3 m-auto bg-[var(--mantine-color-cavela-primary-5)]" />
              </span>
            )}
            <Text control style={{ fontSize: '1.5em' }}>
              {text || 'Ordered'}
            </Text>
          </div>
          {/* <DueDate title="Next milestone on:" date={estimatedDate} /> */}{' '}
          {/* This line is commented to solver issue: 1512-hide-next-milestone-date */}
        </div>
      </div>
      {errorCode ? (
        <ErrorNotification>
          {errorCode}: Failed to load product updates.
        </ErrorNotification>
      ) : (
        <>
          <Tabs tabs={flatTabs} active={activeTab} setActive={setActiveTab} />
        </>
      )}
    </>
  );
};

export default SupplierTabs;
