import { MappedStageFile, StageFile } from '../types/filesTypes';
import {
  LifecycleStage,
  MappedLifecycleStage,
  MappedProductDetail,
  ProductDetail
} from '../types/ProductTypes';
import { getStageCopies } from './copiesGetters';
import { formatLocalDateTime } from './formatLocalDateTime';
import { formatTitle } from './formatters';
import { questionsMapper } from './questionMapper';

export function stageMapper(
  stages: LifecycleStage[],
  productName: string
): MappedLifecycleStage[] {
  return stages.map((stage) => {
    return {
      id: stage.id,
      status: stage.status as 'done' | 'ongoing' | 'pending' | 'blocked',
      label: getStageCopies({
        stageId: stage.id,
        productName,
        numSuppliers: stage.total_contacted_suppliers || undefined,
        status: stage.status,
        statusCode: stage.status_reason_code || null
      }),
      estimatedDate: stage.estimated_done_date || '',
      description: formatTitle(stage.id),
      files: stage.files ? filesMapper(stage.files) : [],
      questions: stage.questions ? questionsMapper(stage.questions) : []
    };
  });
}

export function filesMapper(files: StageFile[]): MappedStageFile[] {
  const mappedFiles = files.map((file: StageFile) => {
    return {
      id: file.id || '',
      fileName: file.name || '',
      fileUrl: file.url || '',
      caption: file.caption || '',
      uploadDate: file.upload_date
        ? formatLocalDateTime(file.upload_date).string
        : '',
      staffUploaded: file.staff_uploaded || false,
      size: file?.size || 0
    };
  });

  return mappedFiles.sort(
    (a, b) =>
      new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime()
  );
}

export function productListMapper(
  products: ProductDetail[]
): MappedProductDetail[] {
  return products.map((product) => productMapper(product));
}

export function productMapper(product: ProductDetail): MappedProductDetail {
  const stages = stageMapper(
    product.detail?.lifecycle || [],
    product.detail?.name || ''
  );

  return {
    productId: product.id,
    productName: formatTitle(product.detail?.name || ''),
    image: product.image,
    createdAt: product.detail.created_at,
    rfq: product.rfq,
    stages,
    detail: product.detail,
    last_relevant_update: product.last_relevant_update
  };
}
