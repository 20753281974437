import { useState } from 'react';

import { ProductCard, ProductItem, Toggle } from '@cavela/ui';

import { MappedProductDetail } from 'src/types/ProductTypes';
import { format } from 'timeago.js';

import { PageHeading, SectionWrapper } from '../../components';
import useProductsList from '../../hooks/useProductstList';
import RfqDisplay from '../RfqDisplay/RfqDisplay';

type StageId =
  | 'rfq_received'
  | 'rfq_sent'
  | 'quotes_received'
  | 'samples_ordered'
  | 'samples_produced'
  | 'order_produced';

const stages = {
  rfq_received: {
    variant: 'in-progress',
    text: 'Sourcing'
  },
  rfq_sent: {
    variant: 'in-progress',
    text: 'Sourcing'
  },
  quotes_received: {
    variant: 'in-progress',
    text: 'Sourcing'
  },
  samples_ordered: {
    variant: 'in-progress',
    text: 'Sampling'
  },
  samples_produced: {
    variant: 'in-progress',
    text: 'Sampling'
  },
  order_produced: {
    variant: 'in-progress',
    text: 'Ordered'
  }
};

const stageNodes = ['Sourcing', 'Sampling', 'Ordered'];

const ProductsDisplay = () => {
  const [isListView, setIsListView] = useState(false);
  const { productsList, loading: productListLoading } = useProductsList();

  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  const onClickToggle = () => setIsListView(!isListView);

  const createProductCard = ({
    product,
    isCard = true
  }: {
    product: MappedProductDetail;
    isCard: boolean;
  }) => {
    const { id = 'rfq_received', estimatedDate } =
      product.stages.find(({ status }) => status !== 'done') || {};

    const isComplete = product.stages.every(
      ({ status = '' }) => status === 'done'
    );

    const estDate = new Date(estimatedDate || 0);
    const isValidDate = Date.now() < +estDate;

    const { text } = stages[id as StageId] || {};
    const milestone = isValidDate && estimatedDate && format(estDate);
    const stageLabel = isComplete ? stages.order_produced.text : text;
    const stageIndex = stageNodes.indexOf(stageLabel);

    const getStatus = () => {
      if (!product.last_relevant_update) return 'in-progress';

      switch (product.last_relevant_update.update_type) {
        case 'block':
          return 'action-required';
        default:
          return product.last_relevant_update.relevant
            ? 'new-update'
            : 'in-progress';
      }
    };

    const cardProps = {
      href: `products/${product.productId}`,
      name: product.productName,
      imgSrc: product.image || '',
      status: getStatus(),
      stage: stageIndex,
      stageLabels: [stageLabel],
      milestone: milestone,
      style: {
        color: 'black'
      }
    };

    return isCard ? (
      <div key={product.productId} className="hover:animate-hover">
        <ProductCard {...cardProps} />
      </div>
    ) : (
      <div key={product.productId}>
        <ProductItem
          key={product.productId}
          {...cardProps}
          style={{
            ...cardProps.style,
            borderBottom: '1px solid var(--mantine-color-cavela-shadow-2)'
          }}
        />
      </div>
    );
  };

  const products = productsList.map((product) =>
    createProductCard({ product, isCard: !isListView })
  );

  const cards = isListView ? (
    <div className="w-full">{products}</div>
  ) : (
    <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full max-w-6xl mx-auto">
      {products}
    </div>
  );

  const table = (
    <table className="flex flex-col bg-white w-full min-w-[60rem] max-w-6xl mx-auto rounded-lg shadow-md">
      <thead>
        <tr
          className="flex gap-4 items-start justify-start text-left min-w-full p-4"
          style={{
            borderBottom: '1px solid var(--mantine-color-cavela-shadow-2)'
          }}
        >
          <th className="min-w-80 uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)]">
            Product name
          </th>
          <th className="w-24 max-w-[25%] uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)]">
            Image
          </th>
          <th className="min-w-[25%] uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)]">
            Stage
          </th>
          <th className="w-24 max-w-[25%] uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)] text-center">
            Created At
          </th>
          <th className="min-w-40 max-w-[25%] uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)]">
            Status
          </th>
        </tr>
      </thead>
      <tbody>{products}</tbody>
    </table>
  );

  return (
    <>
      <PageHeading>Products</PageHeading>
      <div className="my-4 self-start" onClick={onClickToggle}>
        <Toggle toggled={isListView} />
      </div>
      <SectionWrapper testId="products-display">
        <div className="mb-10">
          <RfqDisplay isListView={isListView} />
        </div>
        <h3 className="text-2xl font-extrabold text-black mb-4 grid gap-4 w-full max-w-6xl mx-auto">
          <strong>Sourcing</strong>
        </h3>
        {!productListLoading && (isListView ? table : cards)}
      </SectionWrapper>
    </>
  );
};

export default ProductsDisplay;
